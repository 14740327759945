/**
 * 商业银行
 */
export async function brand(echarts, dom, that) {
	let menber = echarts.init(document.getElementById(dom))
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid: 158
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	// 总数
	menber.resize()
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
			}
		},
		grid: {
			left: '1%',
			right: '2%',
			bottom: '3%',
			containLabel: true
		},
		xAxis: {
			type: 'value',
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		axisLabel: {
			show: true,
			interval: 0,
			formatter: function(params) {
				var newParamsName = "";
				var paramsNameNumber = params.length;
				var provideNumber = 10; // 一行显示几个字 然后就超过字数就会自动换行
				var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
				if (paramsNameNumber > provideNumber) {
					for (var p = 0; p < rowNumber; p++) {
						var tempStr = "";
						var start = p * provideNumber;
						var end = start + provideNumber;
						if (p == rowNumber - 1) {
							tempStr = params.substring(start, paramsNameNumber);
						} else {
							tempStr = params.substring(start, end) + "\n";
						}
						newParamsName += tempStr;
					}
				} else {
					newParamsName = params;
				}
				return newParamsName;
			}
		},

		yAxis: {
			type: 'category',
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				name: '总数',
				type: 'bar',
				stack: 'total',
				label: {
					show: true
				},
				data: total,
				itemStyle: {
					normal: {
						color: "#3784db"
					}
				}
			},
			{
				name: '本月新增',
				type: 'bar',
				stack: 'total',
				label: {
					show: true
				},
				data: month,
				itemStyle: {
					normal: {
						color: "#fff"
					}
				}
			}
		]
	})
}

/**
 * 会员人数
 * @param {*} echarts 
 * @param {*} dom 
 */
export async function menber(echarts, dom, that) {
	let menber = echarts.init(document.getElementById(dom))
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid: 164
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		xAxis: {
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		yAxis: {
			type: "value",
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				type: 'bar',
				data: total,
				barGap: 0,
				name: "总数",
				itemStyle: {
					normal: {
						color: "#3784db"
					}
				},
				label: {
					show: true
				}
			},
			{
				type: 'bar',
				data: month,
				name: "本月新增",
				itemStyle: {
					normal: {
						color: "#fff"
					}
				},
				label: {
					show: true
				}
			}
		]
	});
}

/**
 * 培训
 */
export async function train(echarts, dom, that) {
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid: 165
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	let menber = echarts.init(document.getElementById(dom))
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		color: ['#fcae60', '#fff'],
		xAxis: {
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		yAxis: {
			type: "value",
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				type: 'bar',
				data: total,
				barGap: 0,
				name: "总数",
				label: {
					show: true
				}
			},
			{
				type: 'bar',
				data: month,
				name: "本月新增",
				label: {
					show: true
				}
			}
		]
	});
}

/**
 * 路演
 */
export async function road(echarts, dom, that) {
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid: 166
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	let menber = echarts.init(document.getElementById(dom))
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		color: ['#37dba7', '#fff'],
		xAxis: {
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		yAxis: {
			type: "value",
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				type: 'bar',
				data: total,
				barGap: 0,
				name: '总数',
				label: {
					show: true
				}
			},
			{
				type: 'bar',
				data: month,
				name: '本月新增',
				label: {
					show: true
				}
			}
		]
	});
}

/**
 * 融资
 */
export async function rongzi(echarts, dom, that) {
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid: 207
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	let menber = echarts.init(document.getElementById(dom))
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		color: ['#91cc75', '#fff'],
		xAxis: {
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		
		axisLabel: {
			show: true,
			interval: 0,
			formatter: function(params) {
				var newParamsName = "";
				var paramsNameNumber = params.length;
				var provideNumber = 7; // 一行显示几个字 然后就超过字数就会自动换行
				var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
				if (paramsNameNumber > provideNumber) {
					for (var p = 0; p < rowNumber; p++) {
						var tempStr = "";
						var start = p * provideNumber;
						var end = start + provideNumber;
						if (p == rowNumber - 1) {
							tempStr = params.substring(start, paramsNameNumber);
						} else {
							tempStr = params.substring(start, end) + "\n";
						}
						newParamsName += tempStr;
					}
				} else {
					newParamsName = params;
				}
				return newParamsName;
			}
		},
		
		yAxis: {
			type: "value",
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				type: 'bar',
				data: total,
				barGap: 0,
				name: '总数',
				label: {
					show: true
				}
			},
			{
				type: 'bar',
				data: month,
				name: '本月新增',
				label: {
					show: true
				}
			}
		]
	});
}

/**
 * 投资者教育
 */
export async function investor(echarts, dom, that) {
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid: 163
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	let menber = echarts.init(document.getElementById(dom))
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis'
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		yAxis: {
			type: 'value',
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		color: ["#3784db", "#fff"],
		series: [{
				name: '总数',
				type: 'line',
				data: total,
				markPoint: {
					data: [{
							type: 'max',
							name: 'Max'
						},
						{
							type: 'min',
							name: 'Min'
						}
					]
				},
				markLine: {
					data: [{
						type: 'average',
						name: 'Avg'
					}]
				}
			},
			{
				name: '本月新增',
				type: 'line',
		 	data: month,
				markPoint: {
					data: [{
							type: 'max',
							name: 'Max'
						},
						{
							type: 'min',
							name: 'Min'
						}
					]
				},
				markLine: {
					data: [{
						type: 'average',
						name: 'Avg'
					}]
				}
			}
		]
	})

}

/**
 * 产权交易
 */
export async function listingGpqy(echarts, dom, that) {
	let catid = 0
	if (dom == "listing") {
		catid = 160
	} else if (dom == "deal") {
		catid = 159
	}
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	let menber = echarts.init(document.getElementById(dom))
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		xAxis: {
			type: "value",
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		yAxis: {
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				type: 'bar',
				data: total,
				barGap: 0,
				name: '总数',
				label: {
					show: true
				},
				itemStyle: {
					normal: {
						color: function(params) {
							var colorList = ["#3784db", "#fcae60", "#2bbd8f"]
							return colorList[params.dataIndex]
						}
					}
				}
			},
			{
				type: 'bar',
				data: month,
				name: '本月新增',
				label: {
					show: true
				},
				itemStyle: {
					normal: {
						color: "#fff"
					}
				}
			}
		],
		grid: {
			left: '10',
			bottom: "30",
			top: 30,
			containLabel: true
		},
	});
}

/**
 * 挂牌企业
 */
export async function listingCqjy(echarts, dom, that) {
	let catid = 0
	if (dom == "listing") {
		catid = 160
	} else if (dom == "deal") {
		catid = 159
	}
	let res = await that.$axios.get("Show/getList", {
		params: {
			catid
		}
	})
	// 栏目
	let data = []
	let total = []
	let month = []
	res.data.forEach(item => {
		data.push(item.title)
		total.push(item.total)
		month.push(item.new)
	})
	let menber = echarts.init(document.getElementById(dom))
	menber.resize()
	// 绘制图表
	menber.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		xAxis: {
			type: "value",
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		
		
		
		yAxis: {
			data: data,
			axisLabel: {
				show: true,
				textStyle: {
					color: '#ffffff'
				}
			}
		},
		series: [{
				type: 'bar',
				data: total,
				barGap: 0,
				name: '总数',
				label: {
					show: true
				},
				itemStyle: {
					normal: {
						color: "#3784db"
					}
				}
			},
			{
				type: 'bar',
				data: month,
				name: '本月新增',
				label: {
					show: true
				},
				itemStyle: {
					normal: {
						color: "#fff"
					}
				}
			}
		],
		grid: {
			left: '10',
			bottom: "30",
			top: 30,
			containLabel: true
		},
	});
}
