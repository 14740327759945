<template>
	<div class="pn">
		<div class="hov-tip" style="display: none;">
			<h2 class="tip">{{hovTle}}</h2>
			<ul class="fa-tip">
				<li class="tip-item" v-for="(item,index) in hovTip" :key="index">
					<div class="hov-cle">
						<p class="cle" :style="{backgroundColor:item.color}"></p>
						<p class="hov-count">{{item.text}}</p>
					</div>
					<p class="hov-num">{{item.num}}</p> 
				</li>
			</ul>
		</div>
			
		<div class="tles">
			<h1 class="pa-tle">甘肃股权交易中心可视化数据展示</h1>
		</div>
		
		<div class="panel">
			<!-- 商业银行 -->
			<div>
				<ul class="tle">
					<li class="title">商业银行</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="brand" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 托管企业 -->
			<div id="deposit">
				<div>
					<ul class="tle">
						<li class="title">托管企业</li>
						<li class="new">本月新增</li>
					</ul>
					<ul class="deposit store">
						<li v-for="(item,index) in tgData" :key="index">
							<!-- 新增 -->
							<div class="month load-num">{{item.new}}</div>
							<!-- 总数 -->
							<div class="dp-count1 load-num">{{item.total}}</div>
							<p class="tip">{{item.title}}</p>
						</li>
					</ul>
				</div>
			</div>
			<!-- 会员人数 -->
			<div>
				<ul class="tle">
					<li class="title">会员人数</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="menber" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 产权交易 -->
			<div>
				<ul class="tle">
					<li class="title">产权交易</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="deal" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 专精特新小巨人企业 -->
			<div style="background-color: #081732;">
				<ul class="tle">
					<li class="title">专精特新小巨人企业</li>
					<li class="new">本月新增</li>
				</ul>
				<ul class="smail store">
					<li v-for="(item,index) in xjrData" :key="index" class="smail-item">
						<div class="big-cle">
							<span class="load-num">{{item.new}}</span>
							<div class="sma-cle load-num">{{item.total}}</div>
						</div>
						<div class="sma-txt">
							<template v-if="index==0">
								<p class="txt1">已挂牌专精特新</p>
								<p class="txt2">小巨人企业数</p>
							</template>
							<template v-else-if="index==1">
								<p class="txt1">甘肃省专精特新</p>
								<p class="txt2">小巨人企业总数</p>
							</template>
							<template v-else-if="index==2">
								<p class="txt1">已走访专精特新</p>
								<p class="txt2">小巨人企业数</p>
							</template>
						</div>
					</li>
				</ul>
			</div>
			<!-- 培训 -->
			<div>
				<ul class="tle">
					<li class="title">培训</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="train" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 挂牌企业 -->
			<div>
				<ul class="tle">
					<li class="title">挂牌企业</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="listing" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 投资者&融资 -->
			<div>
				<ul class="tle">
					<li class="title">投资者</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="investor" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 路演 -->
			<div>
				<ul class="tle">
					<li class="title">路演</li>
					<li class="new">本月新增</li>
				</ul>
				<div id="road" class="store" style="flex-grow: 1;"></div>
			</div>
			<!-- 融资 -->
			<div>
				<ul class="tle">
					<li class="title">融资</li>
					<li class="new">本年度新增</li>
				</ul>
				<div id="rongzi" class="store" style="flex-grow: 1;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import * as data from './data.js'
	import anime from "animejs"
	import $ from "jquery"
	export default {
		data() {
			return {
				checked: false,
				num:{
					num1:542,
					num2:71
				},
				hovTip:[],
				// 悬浮提示
				hovTle:"",
				// 托管企业数据展示
				tgData:[],
				// 小巨人企业总数
				xjrData:[]
			}
		},
		created() {
			// 挂牌展示数据
			this.getTg(161)
			// 专精特新小巨人企业
			this.getTg(162)
		},
		mounted() {
			this.$nextTick(() => {
				// 字体rem转换
				this.getFzHt()
				// 商业银行
				data.brand(echarts,"brand",this)
				// 会员服务
				data.menber(echarts,"menber",this)
				// 培训
				data.train(echarts,"train",this)
				// 路演
				data.road(echarts,"road",this)
				// 融资
				data.rongzi(echarts,"rongzi",this)
				// 产权交易
				data.listingGpqy(echarts,"deal",this)
				// 挂牌企业
				data.listingCqjy(echarts,"listing",this)
				
				// 投资者教育
				data.investor(echarts,"investor",this)
				// 数据加载变化
				this.loadNum("load-num")
				// 悬浮产权交易
				this.hovDeposit()
				
				window.onresize = () => {
					// 字体rem转换
					this.getFzHt()
					// 商业银行
					data.brand(echarts,"brand",this)
					// 会员服务
					data.menber(echarts,"menber",this)
					// 培训
					data.train(echarts,"train",this)
					// 路演
					data.road(echarts,"road",this)
					// 产权交易
					data.listingGpqy(echarts,"deal",this)
					// 挂牌企业
					data.listingCqjy(echarts,"listing",this)
					// 投资者教育
					data.investor(echarts,"investor",this)
					// 融资
					data.rongzi(echarts,"rongzi",this)
				}
			})
		},
		methods: {
			getFzHt() {
				// 获取html
				var html = document.documentElement;
				// 获取html的宽度
				var windowWidth = html.clientWidth;
				// 设置html的字体大小
				// 屏幕宽度/设计稿宽度
				html.style.fontSize = windowWidth / 1920 * 100 + 'px';
			},
			// 加载数字
			loadNum(className) {
				var roundLogEl = document.getElementsByClassName(className);
				for(let i=0;i<roundLogEl.length;i++) {
					let num = roundLogEl[i].innerText
					anime({
						targets: roundLogEl[i],
						innerText: [0,num],
						easing: 'linear',
						round: 1,
						duration: 1500
					});
				}
			},
			// 悬浮专精特新小巨人
			hovNewsPeo() {
				let that = this
				$(".big-cle").mousemove(function(e){
					let newMon = $(this).children(".load-num")
					// 获取当月新增
					let num1 = newMon.eq(0)
					// 获取总数
					let num2 = newMon.eq(1)
					$(".hov-tip").css({display:"block"})
					$(".hov-tip").animate({left:(e.pageX+20)+"px",top:(e.pageY+20)+"px"},1)
					// 获取兄弟元素
					
					let obj1 = {
						text:"本月新增",
						color:$(this).css("backgroundColor"),
						num:num1.text()
					}
					let obj2 = {
						text:"总数",
						color:num2.css("backgroundColor"),
						num:num2.text()
					}
					let hovTip = [obj1,obj2]
					that.hovTip = hovTip
					that.hovTle = $(this).next(".sma-txt").children(".txt1").text()
				})
				$(".big-cle").mouseleave(function(){
					$(".hov-tip").css({display:"none"})
				})
			},
			// 悬浮托管企业
			hovDeposit() {
				let that = this
				$(".deposit").delegate("li","mousemove",function(e){
					let num1 = $(this).children(".load-num").eq(0)
					let num2 = $(this).children(".load-num").eq(1)
					
					$(".hov-tip").css({display:"block"})
					$(".hov-tip").animate({left:(e.pageX+20)+"px",top:(e.pageY+20)+"px"},1)
					
					let obj1 = {
					 	text:"本月新增",
					 	color:num1.css("background-color"),
					 	num:num1.text()
					}
					let obj2 = {
					 	text:"总数",
					 	color:num2.css("background-color"),
					 	num:num2.text()
					}
					let hovTip = [obj1,obj2]
					that.hovTip = hovTip
					that.hovTle = $(this).children(".tip").text()
				})
				
				$(".deposit").delegate("li","mouseleave",function(){
					$(".hov-tip").css({display:"none"})
					$(this).css("background-color","")
				})
			},
			// 托管企业数据展示
			async getTg(catid) {
				let res = await this.$axios.get("Show/getList",{params:{catid}})
				// 栏目
				if(res.code) {
					if(catid==161) {
						this.tgData = res.data
					}else if(catid==162) {
						this.xjrData = res.data
						this.$nextTick(()=>{
							// 悬浮专精特新小巨人
							this.hovNewsPeo()
						})
					}
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.store {
		background-color: #081732;
	}
	// 悬浮窗
	.hov-tip {
		display: inline-block;
		background-color: #fff;
		padding: 10px;
		border-radius: 3px;
		position: absolute;
		z-index: 99999;
		box-shadow: 0 0 5px rgba(0,0,0,.3);
		.tip {
			font-size: 16px;
			color: #666;
			font-weight: 500;
		}
		.fa-tip {
			.tip-item {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				margin-top: 10px;
				.hov-cle {
					display: flex;
					align-items: center;
					.cle {
						width: 10px;
						height: 10px;
						background-color: red;
						border-radius: 50%;
						margin-right: 7px;
					}
					.hov-count {
						font-size: 15px;
						color: #666;
						margin-right: 20px;
					}
				}
				.hov-num {
					font-size: 16px;
					color: #666;
					font-weight: 700;
				}
			}
		}
	}
	.tles {
		background-image: linear-gradient(to bottom,#0d1c35,#1a4278);
		border-bottom:2px solid #ddeaf5;
		.pa-tle {
			font-size: 50px;
			background: linear-gradient(to right, rgba(199, 247, 252, 1) 0%, rgba(255, 255, 255, 1));
			-webkit-background-clip: text;
			color: transparent;
		   // 转变为行内块元素 文字渐变才会生效
			display: inline-block;
			display: flex;
			justify-content: center;
			padding: 5px 0;
			
		}
	}
	
	.pn {
		box-sizing: border-box;
		background-color: #053f76;
		padding-top: 0;
	}
	.panel {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
		row-gap: 20px;
		padding: 20px;
		box-sizing: border-box;
		&>div {
			height: 400px;
			background-color: #fff;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			position: relative;
			border-radius: 10px;
			overflow: hidden;
			&#deposit {
				height: 400px;
				align-self: flex-end;
				background-color: #081732;
			}
			.tle {
				padding: 15px 10px;
				background-image: linear-gradient(to bottom,#0d1c35,#1a4278);
				border-bottom:1px solid #ddeaf5;
				color: #f2f4f7;
				.new {
					font-size: 14px;
					display: flex;
					align-items: center;
					&:before {
						display: block;
						content: '';
						width: 15px;
						height: 15px;
						background-color: #fff;
						border-radius: 2px;
						margin-right: 5px;
					}
				}
				&:first-child {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.title {
						font-size: 20px;
					}
					.month {
						display: flex;
					}
				}
			}
		}
	}
	
	// 专精小巨人企业
	.smail {
		margin-top: 11%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 30px;
		color: #fff;
		.smail-item {
			text-align: center;
			&:nth-child(1) {
				.big-cle {
					border: 2px solid #5470c6;
					color: #5470c6;
					box-shadow: 0 0 10px rgba(84, 112, 198,.5);
					background-color: rgba(84, 112, 198,.1);
					.sma-cle {
						border: 2px solid #5470c6;
						background-color: #5470c6;
						box-shadow: 0 0 10px rgba(84, 112, 198,.5);
					}
				}
			}
			&:nth-child(2) {
				.big-cle {
					width: 163px;
					height: 163px;
					border: 2px solid #fcae60;
					color: #fcae60;
					box-shadow: 0 0 10px rgba(252, 174, 96,.5);
					background-color: rgba(252, 174, 96,.1);
					.sma-cle {
						width: 115px;
						height: 115px;
						border: 2px solid #fcae60;
						background-color: #fcae60;
						box-shadow: 0 0 10px rgba(252, 174, 96,.5);
					}
				}
			}
			&:nth-child(3) {
				.big-cle {
					border: 2px solid #91cc75;
					color: #91cc75;
					box-shadow: 0 0 10px rgba(145, 204, 117,.5);
					background-color: 0 0 10px rgba(145, 204, 117,.1);
					.sma-cle {
						border: 2px solid #91cc75;
						background-color: #91cc75;
						box-shadow: 0 0 10px rgba(145, 204, 117,.5);
					}
				}
				
			}
			.big-cle {
				width: 143px;
				height: 143px;
				border-radius: 50%;
				position: relative;
				font-size: 17px;
				box-sizing: border-box;
				padding-top: 10px;
				.sma-cle {
					width: 100px;
					height: 100px;
					border: 1px solid var(--themeColor);
					border-radius: 50%;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 20px;
					color: white;
				}
			}
			.sma-txt {
				.txt1 {
					font-size: 15px;
					margin: 10px 0 5px 0;
				}
				.txt2 {
					font-size: 15px;
				}
			}
		}
	}
	
	// 托管企业展示
	.deposit {
		display: grid;
		grid-template-columns: repeat(2,1fr);
		column-gap: 20px;
		box-sizing: border-box;
		margin-top: 10%;
		padding: 0 20px;
		li {
			.tip {
				font-size: 16px;
				color: #fff;
			}
			width: 100%;
			&:nth-child(1) {
				div:nth-child(2) {
					background-image: linear-gradient(to top, rgba(84, 112, 198,1),rgba(84, 112, 198,0));
				}
			}
			&:nth-child(2) {
				div:nth-child(2) {
					background-image: linear-gradient(to top, rgba(252, 174, 96,1),rgba(252, 174, 96,0));
				}
			}
			div {
				font-size: 20px;
				height: 95px;
				line-height: 95px;
				justify-content: center;
				width: 100%;
				color: #fff;
				text-align: center;
				position: relative;
				background-clip: content-box !important;
				padding: 0 15px;
				box-sizing: border-box;
				&::after {
					display: block;
					content: '';
					width: 100%;
					height: 2px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
				&:nth-child(2) {
					margin-top: 20px;
				}
				&.month {
					background-image: linear-gradient(to top, rgba(255,255,255,1),rgba(255,255,255,0));
					&::after {
						background-color: #fff;
					}
				}
				&.dp-count1 {
					&::after {
						background-color: #5470c6;
					}
				}
				
			}
			p {
				font-size: 15px;
				text-align: center;
				margin-top: 10px;
			}
			&:nth-child(2) {
				div {
					&.dp-count1 {
						&::after {
							background-color: #fcae60;
						}
					}
				}
			}
		}
	}
	
	@media (max-width:1550px) {
		.panel {
			grid-template-columns: repeat(2, 1fr);
			&>div {
				height: 500px;
				&#deposit {
					height: 400px;
				}
			}
		}
		.pa-tle {
			right: 0;
			display: none;
		}
		.panel >div#deposit {
			height: 500px;
		}
		.deposit li div:nth-child(2) {
		    margin-top: 50px;
		}
		.deposit li div {
			height: 120px;
			line-height: 120px;
			font-size: 24px;
		}
		.smail .smail-item {
			padding: 0 20px;
		}
		.smail .smail-item .big-cle {
			width: 173px;
			height: 173px;
			font-size: 24px;
			padding-top: 15px;
		}
		.smail .smail-item .big-cle .sma-cle {
			width: 110px;
			height: 110px;
		}
		.smail .smail-item:nth-child(2) .big-cle {
			width: 203px;
			height: 203px;
		}
	}
	
	@media (max-width:1400px) {
		.smail .smail-item .big-cle {
			width: 153px;
			height: 153px;
			font-size: 22px;
			padding-top: 12px;
		}
		.smail .smail-item {
			padding: 0 10px;
		}
		.smail .smail-item .big-cle .sma-cle {
			width: 100px;
			height: 100px;
		}
		.smail .smail-item:nth-child(2) .big-cle {
			width: 183px;
			height: 183px;
		}
		.deposit {
			margin-top: 15%;
		}
		.deposit li div:nth-child(2) {
		    margin-top: 50px;
		}
		.deposit li div {
			height: 100px;
			line-height: 100px;
			font-size: 24px;
		}
		.panel > div {
			height: 450px;
		}
		.panel > div#deposit {
			height: 450px;
		}
	}
	@media (max-width:1200px) {
		.panel > div {
			height: 400px;
		}
		.panel > div#deposit {
			height: 400px;
		}
		.deposit {
			margin-top: 12%;
		}
		.deposit li div {
			height: 80px;
			line-height: 80px;
		}
		.smail .smail-item .big-cle {
			width: 143px;
			height: 143px;
			font-size: 18px;
			padding-top: 12px;
		}
		.smail .smail-item .big-cle .sma-cle {
			font-size: 18px;
		}
		.smail .smail-item {
			padding: 0 10px;
		}
		.smail .smail-item .big-cle .sma-cle {
			width: 90px;
			height: 90px;
		}
		.smail .smail-item:nth-child(2) .big-cle {
			width: 163px;
			height: 163px;
		}
		.deposit li div {
			font-size: 18px;
		}
	}
	@media (max-width:1100px) {
		.smail .smail-item .big-cle {
			width: 133px;
			height: 133px;
			font-size: 18px;
			padding-top: 12px;
		}
		.smail .smail-item .big-cle .sma-cle {
			font-size: 18px;
		}
		.smail .smail-item {
			padding: 0 5px;
		}
		.smail .smail-item .big-cle .sma-cle {
			width: 80px;
			height: 80px;
		}
		.smail .smail-item:nth-child(2) .big-cle {
			width: 153px;
			height: 153px;
		}
		.smail .smail-item:nth-child(2) .big-cle .sma-cle {
			width: 100px;
			height: 100px;
		}
	}
	
	@media (max-width:1000px) {
		.smail .smail-item .big-cle {
			width: 123px;
			height: 123px;
			font-size: 18px;
			padding-top: 12px;
		}
		.smail .smail-item .big-cle .sma-cle {
			font-size: 18px;
		}
		.smail .smail-item {
			padding: 0;
		}
		.smail .smail-item .big-cle .sma-cle {
			width: 70px;
			height: 70px;
		}
		.smail .smail-item:nth-child(2) .big-cle {
			width: 143px;
			height: 143px;
		}
		.smail .smail-item:nth-child(2) .big-cle .sma-cle {
			width: 90px;
			height: 90px;
		}
		.panel > div .tle:first-child .title {
			font-size: 18px;
		}
		.panel > div {
			height: 350px;
		}
		.panel > div#deposit {
			height: 350px;
		}
	}
</style>
